export const makeCSV = (hours) => {
  const data = hours.results.map((item) => ({
    date: new Date(item.datetime_created).toISOString().split('T')[0],
    fundraiserName: item.fundraiser_full_name,
    campaignName: item.campaign_name,
    campaignLocation: item.location,
    donations: item.success_donation_count,
    leads: item.lead_count,
    workedHours: item.worked_hours,
    canvassingOrg: item.canvassing_org_name,
    avgDonationAmount: item.avg_donation_amount,
    avgDonorAge: item.avg_age_donors,
    donorAgeOver30: item.count_donor_over_30,
    homeOffice: item.home_office,
  }))

  const headers = [
    'Date',
    'Fundraiser Name',
    'Campaign Name',
    'Campaign Location',
    'Donations',
    'Leads',
    'Worked Hours',
    'Canvassving Org',
    'Avg Donation Amount',
    'Avg Donor Age',
    'Donor Age over 30',
    'Home Office',
  ]
  const fileName = 'Fundraiser_Stats_by_Day.csv'

  downloadCSV(data, headers, fileName)
}
const downloadCSV = (data, headers, fileName) => {
  const csvContent = [headers, ...data.map((item) => Object.values(item))]
    .map((e) => e.join(','))
    .join('\n')
  const encodedUri = encodeURI(`data:text/csv;charset=utf-8,\ufeff${csvContent}`)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
